import { makeStyles } from "@material-ui/core";

export const style = makeStyles({
  root: {
    minWidth: 275,
  },
  cardContaner: {
    display: "flex",
    alignItems: "center",
    gap: "14px",
  },
  card: {
    height: "100%",
    display: "flex",
    alignItems: "center",
  },
  icon: {
    backgroundColor: "#eff8fa",
    width: "36px",
    height: "36px",
    "border-radius": "50%",
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
    "& img": {
      maxWidth: "25px",
      maxHeight: "25px",
    },
  },
  textContianer: {
    "& h6": {
      marginBottom: "0px",
    },
  },
  sideCard: {
    display: "flex",
    alignItems: "center",
    gap: "22px",
    cursor: "pointer",
    "& span": {
      font: "normal normal normal 16px/20px Lexend Deca",
      color: "#5EBDCC",
      opacity: 1,
    },
  },
  graphCard: {
    marginTop: "20px",
  },
  subTitle: {
    display: "flex",
    gap: 10,
    alignItems: "baseline",
  },
  viewMore: {
    color: "#5EBDCC",
    textDecoration: "underline",
    cursor: "pointer",
  },

  subTitleAmt: {
    font: "normal normal normal 18px/23px Lexend Deca",
    letterSpacing: "0px",
    color: "#5EBDCC",
    opacity: 1,
  },
  subTitleText: {
    font: "normal normal normal 12px/15px Lexend Deca",
    letterSpacing: "0px",
    color: "#707070",
  },
  resourceHealthLabel: {
    fontSize: "16px",
    color: "#898585",
  },
  subDescription: {
    fontSize: 12,
  },
  closeIconSingleRes: {
    position: "absolute",
    top: "8px",
    left: "92%",
    color: "gray",
    cursor: "pointer",
  },
  paper: {
    position: "absolute",
    backgroundColor: "#eff8fa",
    padding: "12px 18px",
    top: "50%",
    left: "50%",
    transform: "translate(-50% ,-50%)",
    textAlign: "center",
    borderRadius: "10px",
    outline: "none",
    maxHeight: "80vh",
    overflowY: "auto",
  },
});

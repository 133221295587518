import {
  Tabs,
  Tab,
  Paper,
  Box,
  Checkbox,
  TextField,
  Button,
  Typography,
} from "@material-ui/core";
import React, { useContext, useEffect } from "react";
import ProjectDashboard from "./projectDashboard";
import InfoIcon from "@material-ui/icons/Info";
import BreadCrumb from "../../../components/breadCrumb/BreadCrumb";
import { useLocation, useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useState } from "react";
import InvestmentProfile from "../../investmentProfile/InvestmentProfile";
import { Alert } from "@mui/material";
import Health from "./health";
import Teams from "../../Teams";
import CommunicationPage from "./communication";
import EpicDropDown from "../../../components/epicDropDown";
import { GlobalData } from "../../../context/globalData";
import NormalSelect from "../../../components/select/normalSelect";
import RangeSelector from "../../../components/rangeSelector";
import SelectField from "../../../components/select/selectField";
import DateRangeOutlinedIcon from "@material-ui/icons/DateRangeOutlined";
import { getSelectedDate } from "../../../utils/moduleTabApi";
import EffortAllocation from "./effortAllocation";
import { config } from "../../../Config/permission";
import Dora from "../../DoraMetrics/Index";
import WorkAllocation from "../../workAllocation";

function ProjectDetail() {
  const { projectRangePickerValue, setSavePageData } = useContext(GlobalData);
  const search = useLocation().search;
  const tabIndex = useLocation();
  const searchParams = new URLSearchParams(search);
  const projectName = searchParams?.get("projectName");
  const [iteration, setIteration] = useState(30);
  const [value, setValue] = React.useState(4);
  const [epicData, setEpicData] = useState({ url: "", selectedEpic: [] });
  const { projectId, pathname } = useParams();
  // const [date, setDate] = useState(projectRangePickerValue);
  // const [openRange, setOpenRange] = useState(false);
  const [pageName, setPageName] = useState();
  const history = useHistory();
  const { setIsDefinaionPopup, setDefinationKPI, setSelectedprojectId } =
    useContext(GlobalData);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const epicDropDownHandler = (url, list) => {
    setEpicData({ url: url?.trim(), selectedEpic: list });
  };
  // this is for team tab remember
  useEffect(() => {
    if (tabIndex?.state?.data) {
      setValue(tabIndex?.state?.data);
    } else {
      setValue(value);
    }
  }, [tabIndex]);

  useEffect(() => setSelectedprojectId(projectId), [projectId]);

  const getComponent = (key) => {
    switch (key) {
      case 0:
        return (
          <>
            <ProjectDashboard
              projectName={projectName}
              projectId={projectId}
              // epicUrl={epicData.url}
              // date={date}
              // iteration={iteration}
            />
          </>
        );
      case 1:
        return <InvestmentProfile />;
      case 2:
        return <Teams showBreadCrumb={false} />;
      case 4:
        return <Health />;
      case 3:
        return (
          <CommunicationPage
          // epicUrl={epicData.url}
          // date={date}
          // iteration={iteration}
          />
        );
      case 5:
        return <EffortAllocation projectId={projectId} />;
      case 6:
        return <Dora projectId={projectId} />;
      // case 7:
      //   return <WorkAllocation />;
    }
  };

  // switch (value) {
  //   case 0:
  //     return (setPageName("Project Metric"))
  //   case 1:
  //     return (setPageName("Investment "))
  //   case 2:
  //     return (setPageName("Project Metric"))
  //   default:
  //     break;
  // }

  const tabNames = [
    "Project Metrics",
    "Investment Distribution",
    "Team",
    "Communication & Complexity",
    "Health",
    "Effort allocation",
  ];
  const selectedTabName = tabNames[value];
  const savePageToSee = (tab) => {
    setSavePageData({
      pageRout: `/project/${projectId}`,
      tab: tab,
      name: selectedTabName,
    });
  };

  return (
    <>
      {/* TO DO create new page for Kpi and remove from here */}
      {epicData?.selectedEpic?.length > 0 ? (
        <BreadCrumb
          // backHandler={() => {
          //   history.push(`/project/${projectId}?projectName=${projectName}`);
          // }}
          backHandler={() => {
            history.push("/project");
          }}
          list={["Projects", projectName]}
          active={`${epicData?.selectedEpic?.length} EPICS Selected`}
        />
      ) : (
        <BreadCrumb
          backHandler={() => {
            history.push("/project");
          }}
          list={["Projects"]}
          active={projectName}
        />
      )}

      <Box mb={4}>
        <Paper>
          <Tabs
            value={value}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleChange}
            // aria-label="disabled tabs example"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
          >
            <Tab label="Project Metrics" />
            <Tab
              label={
                <Box display={"flex"}>
                  Investment Distribution&nbsp;
                  <span
                    onClick={() => {
                      setIsDefinaionPopup(true);
                      setDefinationKPI("Investment profiles");
                    }}
                  >
                    <InfoIcon style={{ color: "gray" }} />
                  </span>
                </Box>
              }
            />
            <Tab
              label="Teams"
              // onClick={() =>
              //   history.push(
              //     `/project/${projectId}/teams?projectName=${projectName}`
              //   )
              // }
            />
            <Tab
              label="Communication & Complexity "
              className={config.project.projectTabCommunication ? "" : "d-none"}
            />
            <Tab label="Health" />
            <Tab
              label={
                <Box display={"flex"}>
                  Effort allocation&nbsp;
                  <span
                    onClick={() => {
                      setIsDefinaionPopup(true);
                      setDefinationKPI("Allocation");
                    }}
                  >
                    <InfoIcon style={{ color: "gray" }} />
                  </span>
                </Box>
              }
            />
            <Tab label="Dora Metrics" />
            {/* <Tab label="Resource Allocation" /> */}
          </Tabs>
        </Paper>
      </Box>

      <Box display={"flex"}>
        {/* {(value === 0 || value === 3) && (
          <EpicDropDown epicDropDownHandler={epicDropDownHandler} />
        )} */}
        {(value === 0 || value === 3) && (
          <>
            {/* <div>
              <NormalSelect
                option={[
                  { label: "Monthly", value: 30 },
                  { label: "Biweekly", value: 14 },
                  { label: "Weekly", value: 7 },
                  // { label: "Daily", value: 1 },
                ]}
                value={iteration}
                handleChange={(value) => setIteration(value)}
              />
            </div> */}
            {/* <div style={{ marginTop: -8 }}>
              <SelectField
                input={
                  <Button
                    onClick={() => setOpenRange(true)}
                    style={{
                      border: "1px solid #b7b7b7",
                      width: "230px",
                      textTransform: "capitalize",
                      padding: "5px",
                      fontSize: "14px",
                      fontWeight: 400,
                      paddingLeft: "14px ",
                      paddingRight: "11px",
                      justifyContent: "space-between",
                      borderRadius: "5px",
                      height: "54px",
                    }}
                    endIcon={<DateRangeOutlinedIcon />}
                  >
                    {getSelectedDate(date)}
                  </Button>
                }
                onClick={() => setOpenRange(true)}
              />
              <RangeSelector
                open={openRange}
                setOpen={setOpenRange}
                pickerHandler={(val) => setDate(val)}
                value={date}
                isNextDate={false}
              />
            </div> */}
          </>
        )}
      </Box>
      {/* <Box position={"absolute"} top={"96px"} right={"30px"}>
        <Button
          variant="contained"
          style={{
            background: "#5EBDCC",
            color: "#fff",
            marginLeft: "30px",
            height: "47px",
            width: 120,
          }}
          onClick={() => savePageToSee(value)}
        >
          Save
        </Button>
      </Box> */}

      {getComponent(value)}
    </>
  );
}

export default ProjectDetail;

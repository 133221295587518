import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import { format } from "date-fns";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import React, { useEffect, useState } from "react";
import { TimeLine } from "../../../utils/levelMap";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  saveicon: {
    width: 30,
    height: 30,
    backgroundColor: "red",
  },
  "MuiTab-root": {
    padding: 0,
  },
  tablecont: {
    marginTop: "40px",
    "& td": {
      padding: "20px",
    },
    "& td:last-child": {
      borderRight: "0px solid #80808047",
    },
    "& .MuiPaper-elevation1": {
      boxShadow: "none",
    },
    "& .MuiTableCell-head": {
      boxShadow: "none",
      color: "black",
      fontWeight: "bold",
      background: "#F2F2F2 0% 0% no-repeat padding-box",
      textAlign: "center",
    },
  },
  stepContent: {
    "& label": {
      display: "block",
    },
  },
  bld: {
    fontWeight: "bold",
    whiteSpace: "nowrap",
  },
  flx: {
    display: "flex",
    gap: "5px",
  },
  wd: {
    width: "15%",
    color: "#5ebdcc",
    cursor: "pointer",
    fontWeight: "500",
  },
  whtspc: {
    whiteSpace: "nowrap",
  },
  crd: {
    border: "1px solid lightgray",
    padding: "20px 26px ",
    marginTop: "20px",
    boxShadow: "5px 5px 17px lightgrey",
    borderRadius: "8px",
  },
  fl: {
    display: "flex",
    gap: "10px",
  },
});

const TablePage = ({ searchJiraIssue, keysOfObject, value = "logOne" }) => {
  const classes = useStyles();
  const [dataOfTable, setDataOfTable] = useState(searchJiraIssue);
  useEffect(() => {}, [searchJiraIssue]);
  useEffect(() => {
    setDataOfTable(searchJiraIssue);
  }, [searchJiraIssue]);

  return (
    <>
      <TableContainer
        component={Paper}
        className={classes.tablecont}
        elevation={0}
      >
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              {keysOfObject?.map((header, index) => {
                return (
                  header?.title !== "Expand" &&
                  header?.title !== "url" && (
                    <TableCell align="left" key={index}>
                      <div>{header?.title}</div>
                    </TableCell>
                  )
                );
              })}
              <TableCell className={classes.wd} />
            </TableRow>
          </TableHead>
          <TableBody>
            {dataOfTable?.map((item, index) => (
              <ExapndRow
                key={index}
                item={item}
                keysOfObject={keysOfObject}
                searchJiraIssue={item?.Expand}
                logValue={value}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

const ExapndRow = (props) => {
  const classes = useStyles();
  const { item, keysOfObject, searchJiraIssue: data, logValue } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [isRotate, setIsRotae] = useState(false);
  const [searchJiraIssueTableData, setSearchJiraIssueTableData] = useState([]);
  useEffect(() => {
    setIsOpen(false);
  }, [item, logValue]);

  useEffect(() => {
    // if (data) {
    //   const newData = data?.map((item) => {
    //     let dayValue;
    //     let dates = item?.diffInHours.split(",");
    //     let dataValue = dates[0].includes("d");
    //     if (dataValue === true) {
    //       dayValue = dates[0];
    //     } else {
    //       dayValue = item?.diffInHours;
    //     }
    //     return { ...item, diffInDays: dayValue };
    //   });
    if (logValue === "logOne") {
      setSearchJiraIssueTableData(data?.logOne);
    } else {
      setSearchJiraIssueTableData(data?.logTwo);
    }

    // }
  }, [data, logValue]);

  return (
    <>
      <TableRow
        onClick={(e) => {
          setIsOpen(!isOpen);
          setIsRotae(!isRotate);
        }}
      >
        {keysOfObject?.map(
          (content) =>
            content.title !== "Expand" &&
            content.title !== "url" && (
              <TableCell align="center" style={{ width: "12%" }}>
                <>
                  {content["title"] === "Epic Key" ? (
                    <a target="_blank" href={item.url} rel="noreferrer">
                      {item[content.keyItem]}
                    </a>
                  ) : (
                    <>
                      {content["title"] !== "Expand" && (
                        <label>
                          {content.keyItem === "CreatedDate"
                            ? item?.CreatedDate !== "No Date" &&
                              format(
                                new Date(item["CreatedDate"]),
                                "dd-MMM-yyyy"
                              )
                            : content.keyItem === "ResolutionDate"
                            ? item?.ResolutionDate !== "No Date" &&
                              format(
                                new Date(item["ResolutionDate"]),
                                "dd-MMM-yyyy"
                              )
                            : item[content.keyItem]}
                        </label>
                      )}
                    </>
                  )}
                </>
              </TableCell>
            )
        )}
        <TableCell className={classes.wd} style={{ color: "#5ebdcc" }}>
          <Box display={"flex"} width={"100px"}>
            <ChevronRightIcon
              style={{
                transform: `rotate(${isOpen ? "90deg" : "0Deg"})`,
              }}
            />
            <div>{isOpen ? "View Less" : "View More"}</div>
          </Box>
        </TableCell>
      </TableRow>
      {isOpen && (
        <TableRow>
          <TableCell align="left" colSpan={keysOfObject?.length}>
            <div className="items-wrap-two">
              <div className="items-wrap">
                <div className="arrow-container">
                  {searchJiraIssueTableData?.map((content) => {
                    return (
                      <>
                        {logValue === "logOne" ? (
                          <>
                            <div className="Arrow-main">
                              <div
                                className="arrow-top-line"
                                style={{
                                  background: `${TimeLine[content["from"]]}`,
                                }}
                              ></div>
                              <Tooltip title={content["diffInHours"]}>
                                <div className="contain">
                                  {/* <h3 className={classes.bld}>From : </h3> */}
                                  <h3
                                    className={classes.whtspc}
                                    style={{
                                      color: `${TimeLine[content["from"]]}`,
                                    }}
                                  >
                                    {content["from"]}
                                  </h3>
                                  <label>{content["percent"]}</label>
                                </div>
                              </Tooltip>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="Arrow-main">
                              <div
                                className="arrow-top-line"
                                style={{
                                  background: `${TimeLine[content["from"]]}`,
                                }}
                              ></div>
                              <div className="contain">
                                <div className="px-4 text-muted fs-6">
                                  {content["createdDate"] &&
                                    format(
                                      new Date(content["createdDate"]),
                                      "dd-MMM-yyyy HH:mm"
                                    )}
                                </div>
                                <div className="fw-bold fs-4 ps-4">
                                  {content["assignee"]}
                                </div>
                                <div className="fs-5 ps-4">
                                  {content["from"] && (
                                    <>
                                      <span className="text-warning">
                                        {content["from"]}
                                      </span>
                                      {" to "}
                                    </>
                                  )}
                                  <span className="text-success">
                                    {content["to"]}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </>
                    );
                  })}
                </div>
              </div>
            </div>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

export default TablePage;
